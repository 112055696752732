// source: data.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.Area', null, global);
goog.exportSymbol('proto.AreaItem', null, global);
goog.exportSymbol('proto.ItemType', null, global);
goog.exportSymbol('proto.MapData', null, global);
goog.exportSymbol('proto.MapInfo', null, global);
goog.exportSymbol('proto.Marker', null, global);
goog.exportSymbol('proto.UndergroundMap', null, global);
goog.exportSymbol('proto.UndergroundMapChunk', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Marker = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Marker.repeatedFields_, null);
};
goog.inherits(proto.Marker, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Marker.displayName = 'proto.Marker';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AreaItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AreaItem.repeatedFields_, null);
};
goog.inherits(proto.AreaItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AreaItem.displayName = 'proto.AreaItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Area = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Area.repeatedFields_, null);
};
goog.inherits(proto.Area, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Area.displayName = 'proto.Area';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MapInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.MapInfo.repeatedFields_, null);
};
goog.inherits(proto.MapInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MapInfo.displayName = 'proto.MapInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ItemType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ItemType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ItemType.displayName = 'proto.ItemType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UndergroundMapChunk = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UndergroundMapChunk.repeatedFields_, null);
};
goog.inherits(proto.UndergroundMapChunk, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UndergroundMapChunk.displayName = 'proto.UndergroundMapChunk';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UndergroundMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UndergroundMap.repeatedFields_, null);
};
goog.inherits(proto.UndergroundMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UndergroundMap.displayName = 'proto.UndergroundMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MapData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.MapData.repeatedFields_, null);
};
goog.inherits(proto.MapData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MapData.displayName = 'proto.MapData';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Marker.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Marker.prototype.toObject = function(opt_includeInstance) {
  return proto.Marker.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Marker} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Marker.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFieldWithDefault(msg, 1, 0),
    y: jspb.Message.getFieldWithDefault(msg, 2, 0),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    content: jspb.Message.getFieldWithDefault(msg, 5, ""),
    picture: jspb.Message.getFieldWithDefault(msg, 6, ""),
    itemList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    underground: jspb.Message.getFieldWithDefault(msg, 8, ""),
    video: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Marker}
 */
proto.Marker.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Marker;
  return proto.Marker.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Marker} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Marker}
 */
proto.Marker.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setY(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPicture(value);
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addItem(values[i]);
      }
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnderground(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Marker.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Marker.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Marker} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Marker.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPicture();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getItemList();
  if (f.length > 0) {
    writer.writePackedInt32(
      7,
      f
    );
  }
  f = message.getUnderground();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getVideo();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 x = 1;
 * @return {number}
 */
proto.Marker.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Marker} returns this
 */
proto.Marker.prototype.setX = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 y = 2;
 * @return {number}
 */
proto.Marker.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Marker} returns this
 */
proto.Marker.prototype.setY = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 id = 3;
 * @return {number}
 */
proto.Marker.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.Marker} returns this
 */
proto.Marker.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.Marker.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Marker} returns this
 */
proto.Marker.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string content = 5;
 * @return {string}
 */
proto.Marker.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Marker} returns this
 */
proto.Marker.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string picture = 6;
 * @return {string}
 */
proto.Marker.prototype.getPicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Marker} returns this
 */
proto.Marker.prototype.setPicture = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated int32 item = 7;
 * @return {!Array<number>}
 */
proto.Marker.prototype.getItemList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.Marker} returns this
 */
proto.Marker.prototype.setItemList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.Marker} returns this
 */
proto.Marker.prototype.addItem = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Marker} returns this
 */
proto.Marker.prototype.clearItemList = function() {
  return this.setItemList([]);
};


/**
 * optional string underground = 8;
 * @return {string}
 */
proto.Marker.prototype.getUnderground = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.Marker} returns this
 */
proto.Marker.prototype.setUnderground = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string video = 9;
 * @return {string}
 */
proto.Marker.prototype.getVideo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Marker} returns this
 */
proto.Marker.prototype.setVideo = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AreaItem.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AreaItem.prototype.toObject = function(opt_includeInstance) {
  return proto.AreaItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AreaItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AreaItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, 0),
    typeList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    markerList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    count: jspb.Message.getFieldWithDefault(msg, 6, 0),
    refreshTime: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AreaItem}
 */
proto.AreaItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AreaItem;
  return proto.AreaItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AreaItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AreaItem}
 */
proto.AreaItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIcon(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addType(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMarker(values[i]);
      }
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRefreshTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AreaItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AreaItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AreaItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AreaItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTypeList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getMarkerList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getRefreshTime();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.AreaItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.AreaItem} returns this
 */
proto.AreaItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.AreaItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AreaItem} returns this
 */
proto.AreaItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 icon = 3;
 * @return {number}
 */
proto.AreaItem.prototype.getIcon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.AreaItem} returns this
 */
proto.AreaItem.prototype.setIcon = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated int32 type = 4;
 * @return {!Array<number>}
 */
proto.AreaItem.prototype.getTypeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.AreaItem} returns this
 */
proto.AreaItem.prototype.setTypeList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.AreaItem} returns this
 */
proto.AreaItem.prototype.addType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AreaItem} returns this
 */
proto.AreaItem.prototype.clearTypeList = function() {
  return this.setTypeList([]);
};


/**
 * repeated int32 marker = 5;
 * @return {!Array<number>}
 */
proto.AreaItem.prototype.getMarkerList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.AreaItem} returns this
 */
proto.AreaItem.prototype.setMarkerList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.AreaItem} returns this
 */
proto.AreaItem.prototype.addMarker = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AreaItem} returns this
 */
proto.AreaItem.prototype.clearMarkerList = function() {
  return this.setMarkerList([]);
};


/**
 * optional int32 count = 6;
 * @return {number}
 */
proto.AreaItem.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.AreaItem} returns this
 */
proto.AreaItem.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 refresh_time = 7;
 * @return {number}
 */
proto.AreaItem.prototype.getRefreshTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.AreaItem} returns this
 */
proto.AreaItem.prototype.setRefreshTime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Area.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Area.prototype.toObject = function(opt_includeInstance) {
  return proto.Area.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Area} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Area.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    childList: jspb.Message.toObjectList(msg.getChildList(),
    proto.Area.toObject, includeInstance),
    itemList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    mapId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Area}
 */
proto.Area.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Area;
  return proto.Area.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Area} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Area}
 */
proto.Area.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.Area;
      reader.readMessage(value,proto.Area.deserializeBinaryFromReader);
      msg.addChild(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addItem(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMapId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Area.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Area.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Area} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Area.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChildList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Area.serializeBinaryToWriter
    );
  }
  f = message.getItemList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getMapId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.Area.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Area} returns this
 */
proto.Area.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Area child = 2;
 * @return {!Array<!proto.Area>}
 */
proto.Area.prototype.getChildList = function() {
  return /** @type{!Array<!proto.Area>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Area, 2));
};


/**
 * @param {!Array<!proto.Area>} value
 * @return {!proto.Area} returns this
*/
proto.Area.prototype.setChildList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Area=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Area}
 */
proto.Area.prototype.addChild = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Area, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Area} returns this
 */
proto.Area.prototype.clearChildList = function() {
  return this.setChildList([]);
};


/**
 * repeated int32 item = 3;
 * @return {!Array<number>}
 */
proto.Area.prototype.getItemList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.Area} returns this
 */
proto.Area.prototype.setItemList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.Area} returns this
 */
proto.Area.prototype.addItem = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Area} returns this
 */
proto.Area.prototype.clearItemList = function() {
  return this.setItemList([]);
};


/**
 * optional string map_id = 4;
 * @return {string}
 */
proto.Area.prototype.getMapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Area} returns this
 */
proto.Area.prototype.setMapId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MapInfo.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MapInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.MapInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MapInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MapInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    originX: jspb.Message.getFieldWithDefault(msg, 2, 0),
    originY: jspb.Message.getFieldWithDefault(msg, 3, 0),
    width: jspb.Message.getFieldWithDefault(msg, 4, 0),
    height: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tileOffsetX: jspb.Message.getFieldWithDefault(msg, 6, 0),
    tileOffsetY: jspb.Message.getFieldWithDefault(msg, 7, 0),
    teleportList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MapInfo}
 */
proto.MapInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MapInfo;
  return proto.MapInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MapInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MapInfo}
 */
proto.MapInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOriginX(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOriginY(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTileOffsetX(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTileOffsetY(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTeleport(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MapInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MapInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MapInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MapInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginX();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOriginY();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTileOffsetX();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTileOffsetY();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTeleportList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.MapInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.MapInfo} returns this
 */
proto.MapInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 origin_x = 2;
 * @return {number}
 */
proto.MapInfo.prototype.getOriginX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.MapInfo} returns this
 */
proto.MapInfo.prototype.setOriginX = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 origin_y = 3;
 * @return {number}
 */
proto.MapInfo.prototype.getOriginY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.MapInfo} returns this
 */
proto.MapInfo.prototype.setOriginY = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 width = 4;
 * @return {number}
 */
proto.MapInfo.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.MapInfo} returns this
 */
proto.MapInfo.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 height = 5;
 * @return {number}
 */
proto.MapInfo.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.MapInfo} returns this
 */
proto.MapInfo.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 tile_offset_x = 6;
 * @return {number}
 */
proto.MapInfo.prototype.getTileOffsetX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.MapInfo} returns this
 */
proto.MapInfo.prototype.setTileOffsetX = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 tile_offset_y = 7;
 * @return {number}
 */
proto.MapInfo.prototype.getTileOffsetY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.MapInfo} returns this
 */
proto.MapInfo.prototype.setTileOffsetY = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated int32 teleport = 8;
 * @return {!Array<number>}
 */
proto.MapInfo.prototype.getTeleportList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.MapInfo} returns this
 */
proto.MapInfo.prototype.setTeleportList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.MapInfo} returns this
 */
proto.MapInfo.prototype.addTeleport = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MapInfo} returns this
 */
proto.MapInfo.prototype.clearTeleportList = function() {
  return this.setTeleportList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ItemType.prototype.toObject = function(opt_includeInstance) {
  return proto.ItemType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ItemType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ItemType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    icon: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ItemType}
 */
proto.ItemType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ItemType;
  return proto.ItemType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ItemType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ItemType}
 */
proto.ItemType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIcon(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ItemType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ItemType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ItemType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ItemType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIcon();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.ItemType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ItemType} returns this
 */
proto.ItemType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 icon = 2;
 * @return {number}
 */
proto.ItemType.prototype.getIcon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ItemType} returns this
 */
proto.ItemType.prototype.setIcon = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.ItemType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ItemType} returns this
 */
proto.ItemType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UndergroundMapChunk.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UndergroundMapChunk.prototype.toObject = function(opt_includeInstance) {
  return proto.UndergroundMapChunk.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UndergroundMapChunk} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UndergroundMapChunk.toObject = function(includeInstance, msg) {
  var f, obj = {
    boundList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UndergroundMapChunk}
 */
proto.UndergroundMapChunk.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UndergroundMapChunk;
  return proto.UndergroundMapChunk.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UndergroundMapChunk} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UndergroundMapChunk}
 */
proto.UndergroundMapChunk.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBound(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UndergroundMapChunk.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UndergroundMapChunk.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UndergroundMapChunk} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UndergroundMapChunk.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBoundList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated int32 bound = 1;
 * @return {!Array<number>}
 */
proto.UndergroundMapChunk.prototype.getBoundList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.UndergroundMapChunk} returns this
 */
proto.UndergroundMapChunk.prototype.setBoundList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.UndergroundMapChunk} returns this
 */
proto.UndergroundMapChunk.prototype.addBound = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UndergroundMapChunk} returns this
 */
proto.UndergroundMapChunk.prototype.clearBoundList = function() {
  return this.setBoundList([]);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.UndergroundMapChunk.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UndergroundMapChunk} returns this
 */
proto.UndergroundMapChunk.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UndergroundMap.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UndergroundMap.prototype.toObject = function(opt_includeInstance) {
  return proto.UndergroundMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UndergroundMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UndergroundMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    childList: jspb.Message.toObjectList(msg.getChildList(),
    proto.UndergroundMap.toObject, includeInstance),
    chunkList: jspb.Message.toObjectList(msg.getChunkList(),
    proto.UndergroundMapChunk.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UndergroundMap}
 */
proto.UndergroundMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UndergroundMap;
  return proto.UndergroundMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UndergroundMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UndergroundMap}
 */
proto.UndergroundMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.UndergroundMap;
      reader.readMessage(value,proto.UndergroundMap.deserializeBinaryFromReader);
      msg.addChild(value);
      break;
    case 4:
      var value = new proto.UndergroundMapChunk;
      reader.readMessage(value,proto.UndergroundMapChunk.deserializeBinaryFromReader);
      msg.addChunk(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UndergroundMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UndergroundMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UndergroundMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UndergroundMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChildList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.UndergroundMap.serializeBinaryToWriter
    );
  }
  f = message.getChunkList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.UndergroundMapChunk.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.UndergroundMap.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UndergroundMap} returns this
 */
proto.UndergroundMap.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.UndergroundMap.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UndergroundMap} returns this
 */
proto.UndergroundMap.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated UndergroundMap child = 3;
 * @return {!Array<!proto.UndergroundMap>}
 */
proto.UndergroundMap.prototype.getChildList = function() {
  return /** @type{!Array<!proto.UndergroundMap>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.UndergroundMap, 3));
};


/**
 * @param {!Array<!proto.UndergroundMap>} value
 * @return {!proto.UndergroundMap} returns this
*/
proto.UndergroundMap.prototype.setChildList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.UndergroundMap=} opt_value
 * @param {number=} opt_index
 * @return {!proto.UndergroundMap}
 */
proto.UndergroundMap.prototype.addChild = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.UndergroundMap, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UndergroundMap} returns this
 */
proto.UndergroundMap.prototype.clearChildList = function() {
  return this.setChildList([]);
};


/**
 * repeated UndergroundMapChunk chunk = 4;
 * @return {!Array<!proto.UndergroundMapChunk>}
 */
proto.UndergroundMap.prototype.getChunkList = function() {
  return /** @type{!Array<!proto.UndergroundMapChunk>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.UndergroundMapChunk, 4));
};


/**
 * @param {!Array<!proto.UndergroundMapChunk>} value
 * @return {!proto.UndergroundMap} returns this
*/
proto.UndergroundMap.prototype.setChunkList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.UndergroundMapChunk=} opt_value
 * @param {number=} opt_index
 * @return {!proto.UndergroundMapChunk}
 */
proto.UndergroundMap.prototype.addChunk = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.UndergroundMapChunk, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UndergroundMap} returns this
 */
proto.UndergroundMap.prototype.clearChunkList = function() {
  return this.setChunkList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MapData.repeatedFields_ = [1,2,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MapData.prototype.toObject = function(opt_includeInstance) {
  return proto.MapData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MapData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MapData.toObject = function(includeInstance, msg) {
  var f, obj = {
    areaList: jspb.Message.toObjectList(msg.getAreaList(),
    proto.Area.toObject, includeInstance),
    itemTypeList: jspb.Message.toObjectList(msg.getItemTypeList(),
    proto.ItemType.toObject, includeInstance),
    itemMap: (f = msg.getItemMap()) ? f.toObject(includeInstance, proto.AreaItem.toObject) : [],
    markerMap: (f = msg.getMarkerMap()) ? f.toObject(includeInstance, proto.Marker.toObject) : [],
    mapInfoMap: (f = msg.getMapInfoMap()) ? f.toObject(includeInstance, proto.MapInfo.toObject) : [],
    undergroundMapList: jspb.Message.toObjectList(msg.getUndergroundMapList(),
    proto.UndergroundMap.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MapData}
 */
proto.MapData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MapData;
  return proto.MapData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MapData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MapData}
 */
proto.MapData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Area;
      reader.readMessage(value,proto.Area.deserializeBinaryFromReader);
      msg.addArea(value);
      break;
    case 2:
      var value = new proto.ItemType;
      reader.readMessage(value,proto.ItemType.deserializeBinaryFromReader);
      msg.addItemType(value);
      break;
    case 3:
      var value = msg.getItemMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.AreaItem.deserializeBinaryFromReader, 0, new proto.AreaItem());
         });
      break;
    case 4:
      var value = msg.getMarkerMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.Marker.deserializeBinaryFromReader, 0, new proto.Marker());
         });
      break;
    case 5:
      var value = msg.getMapInfoMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.MapInfo.deserializeBinaryFromReader, "", new proto.MapInfo());
         });
      break;
    case 6:
      var value = new proto.UndergroundMap;
      reader.readMessage(value,proto.UndergroundMap.deserializeBinaryFromReader);
      msg.addUndergroundMap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MapData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MapData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MapData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MapData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAreaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Area.serializeBinaryToWriter
    );
  }
  f = message.getItemTypeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ItemType.serializeBinaryToWriter
    );
  }
  f = message.getItemMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.AreaItem.serializeBinaryToWriter);
  }
  f = message.getMarkerMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.Marker.serializeBinaryToWriter);
  }
  f = message.getMapInfoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.MapInfo.serializeBinaryToWriter);
  }
  f = message.getUndergroundMapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.UndergroundMap.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Area area = 1;
 * @return {!Array<!proto.Area>}
 */
proto.MapData.prototype.getAreaList = function() {
  return /** @type{!Array<!proto.Area>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Area, 1));
};


/**
 * @param {!Array<!proto.Area>} value
 * @return {!proto.MapData} returns this
*/
proto.MapData.prototype.setAreaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Area=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Area}
 */
proto.MapData.prototype.addArea = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Area, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MapData} returns this
 */
proto.MapData.prototype.clearAreaList = function() {
  return this.setAreaList([]);
};


/**
 * repeated ItemType item_type = 2;
 * @return {!Array<!proto.ItemType>}
 */
proto.MapData.prototype.getItemTypeList = function() {
  return /** @type{!Array<!proto.ItemType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ItemType, 2));
};


/**
 * @param {!Array<!proto.ItemType>} value
 * @return {!proto.MapData} returns this
*/
proto.MapData.prototype.setItemTypeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ItemType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ItemType}
 */
proto.MapData.prototype.addItemType = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ItemType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MapData} returns this
 */
proto.MapData.prototype.clearItemTypeList = function() {
  return this.setItemTypeList([]);
};


/**
 * map<int32, AreaItem> item = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.AreaItem>}
 */
proto.MapData.prototype.getItemMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.AreaItem>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.AreaItem));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.MapData} returns this
 */
proto.MapData.prototype.clearItemMap = function() {
  this.getItemMap().clear();
  return this;};


/**
 * map<int32, Marker> marker = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.Marker>}
 */
proto.MapData.prototype.getMarkerMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.Marker>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.Marker));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.MapData} returns this
 */
proto.MapData.prototype.clearMarkerMap = function() {
  this.getMarkerMap().clear();
  return this;};


/**
 * map<string, MapInfo> map_info = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.MapInfo>}
 */
proto.MapData.prototype.getMapInfoMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.MapInfo>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.MapInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.MapData} returns this
 */
proto.MapData.prototype.clearMapInfoMap = function() {
  this.getMapInfoMap().clear();
  return this;};


/**
 * repeated UndergroundMap underground_map = 6;
 * @return {!Array<!proto.UndergroundMap>}
 */
proto.MapData.prototype.getUndergroundMapList = function() {
  return /** @type{!Array<!proto.UndergroundMap>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.UndergroundMap, 6));
};


/**
 * @param {!Array<!proto.UndergroundMap>} value
 * @return {!proto.MapData} returns this
*/
proto.MapData.prototype.setUndergroundMapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.UndergroundMap=} opt_value
 * @param {number=} opt_index
 * @return {!proto.UndergroundMap}
 */
proto.MapData.prototype.addUndergroundMap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.UndergroundMap, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MapData} returns this
 */
proto.MapData.prototype.clearUndergroundMapList = function() {
  return this.setUndergroundMapList([]);
};


goog.object.extend(exports, proto);
